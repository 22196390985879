import { useState, useEffect, useCallback, memo, useMemo } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/system";
import AccessTime from "@mui/icons-material/AccessTime";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { useTheme } from "@mui/material/styles";

const StyledBanner = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: 0,
  width: "100%",
  backgroundColor: "#434343",
  padding: theme.spacing(1),
  textAlign: "center",
  fontSize: "18px",
  color: "#FFFFFF",
  zIndex: 999,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: theme.spacing(1),
}));

const TimerChip = styled(Chip)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "#000000",
  color: "#FFFFFF",
}));

const getExpirationTimestamp = () => {
  const storedTimestamp = localStorage.getItem("couponExpirationTimestamp");

  if (storedTimestamp) {
    return parseInt(storedTimestamp, 10);
  }

  const newExpirationTimestamp =
    new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
  localStorage.setItem(
    "couponExpirationTimestamp",
    newExpirationTimestamp.toString()
  );
  return newExpirationTimestamp;
};

const calculateTimeLeft = (expirationTimestamp) => {
  const difference = expirationTimestamp - new Date().getTime();

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return { days: 0, hours: 0, minutes: 0, seconds: 0 };
};

const formatTimeLeft = (timeLeft) => {
  const { days, hours, minutes, seconds } = timeLeft;
  let result = "";

  if (days > 0) result += `${days}d `;
  if (hours > 0) result += `${hours}h `;
  result += `${minutes}m ${seconds}s`;

  return result;
};

const CouponDisplay = memo(({ coupon, onClose }) => {
  const [timeLeft, setTimeLeft] = useState(() =>
    calculateTimeLeft(getExpirationTimestamp())
  );
  const [isCopied, setIsCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const restartCountdown = useCallback(() => {
    const newExpirationTimestamp =
      new Date().getTime() + (24 * 60 + 10 * 60) * 60 * 1000;
    localStorage.setItem(
      "couponExpirationTimestamp",
      newExpirationTimestamp.toString()
    );
    setTimeLeft(calculateTimeLeft(newExpirationTimestamp));
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft(getExpirationTimestamp());
      setTimeLeft(updatedTimeLeft);

      if (Object.values(updatedTimeLeft).every((value) => value === 0)) {
        restartCountdown();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [restartCountdown]);

  const formattedTimeLeft = useMemo(() => formatTimeLeft(timeLeft), [timeLeft]);

  const handleCopyCode = useCallback(() => {
    navigator.clipboard.writeText(coupon?.CouponCode).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  }, [coupon?.CouponCode]);

  if (!coupon || !isVisible) {
    return null;
  }

  const { CouponCode, Description, DiscountValue } = coupon;

  return (
    // <Slide direction="down" in={isVisible} mountOnEnter unmountOnExit>
    <StyledBanner>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
        spacing={1}
        width="100%"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {/* <LocalFireDepartment sx={{ color: "#FF6B6B" }} /> */}
          <Typography
            variant={isMobile ? "body2" : "body1"}
            component="span"
            sx={{ color: "#FFFFFF", fontWeight: "bold" }}
          >
            {Description} Coupon Code:
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          flexWrap="wrap"
          justifyContent="center"
        >
          <Chip
            label={`${CouponCode}`}
            sx={{
              backgroundColor: "#FFD93D",
              color: "black",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#ffeb3b",
                cursor: "pointer",
              },
            }}
            onClick={handleCopyCode}
            icon={<ContentCopy sx={{ color: "grey !important" }} />}
          />
          <Typography
            variant={isMobile ? "body2" : "body1"}
            component="span"
            sx={{ color: "#FFFFFF", fontWeight: "bold" }}
          >
            ${DiscountValue}% OFF
          </Typography>
          <TimerChip
            icon={<AccessTime sx={{ color: "#FFFFFF !important" }} />}
            label={formattedTimeLeft}
            variant="filled"
            size="small"
          />
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isCopied}
        autoHideDuration={2000}
        onClose={() => setIsCopied(false)}
        message="Coupon code copied to clipboard!"
        TransitionComponent={Fade}
      />
    </StyledBanner>
    // </Slide>
  );
});

export default CouponDisplay;
