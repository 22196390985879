import React from "react";
import { Box, Container, Stack, Skeleton, Typography } from "@mui/material";

const HomeSkeletonLoading = () => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        mt: 10,
      }}
    >
      <Container
        sx={{
          minHeight: "100vh",
        }}
      >
        <Stack
          minHeight="80vh"
          alignItems="center"
          direction={{ xs: "column", lg: "row" }}
          spacing={4}
        >
          <Stack
            spacing={4}
            alignItems={{ xs: "center", lg: "flex-start" }}
            sx={{ marginTop: { xs: "3rem", md: "0px" }, width: "100%" }}
          >
            {/* <Skeleton
              variant="text"
              width="80%"
              // height={80}
              sx={{
                pt: 5,
                marginTop: { xs: "4rem !important", md: 0 },
                height: { xs: 140, md: 80 },
              }}
            /> */}
            <Typography
              variant="h1"
              sx={{
                pt: { xs: 5, lg: 17 },
                marginTop: { xs: "2rem !important", md: 0 },
                fontSize: { xs: "40px", md: "46px" },
                fontWeight: 700,
                color: "black",
                textAlign: { xs: "center", lg: "start" },
              }}
            >
              Unlock Your Certification Success with DirectCertify
            </Typography>
            <Skeleton variant="text" width="100%" height={140} />
            <Skeleton
              variant="rectangular"
              width={150}
              height={50}
              sx={{ borderRadius: 30 }}
            />
          </Stack>
          {/* <Stack width="100%" alignItems="center">
            <Skeleton
              variant="rectangular"
              width="95%"
              // height={400}
              sx={{
                maxWidth: "600px",
                borderRadius: 2,
                height: { xs: 300, md: 400 },
              }}
            />
          </Stack> */}
        </Stack>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={3600}
          sx={{ mt: 4 }}
        />
      </Container>
    </Box>
  );
};

export default HomeSkeletonLoading;
