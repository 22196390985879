import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "20px",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
  overflow: "visible",
  position: "relative",
  background: "linear-gradient(145deg, #ffffff, #f0f0f0)",
}));

const StickyHeader = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  flexWrap: "wrap",
  marginBottom: theme.spacing(2),
}));

const InfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: "10px",
  backgroundColor: "rgba(39, 177, 187, 0.1)",
}));
const ExamDetailsSkeleton = () => (
  <Box sx={{ my: 10 }}>
    <Container sx={{ marginTop: { xs: "125px", md: "110px" } }}>
      <Skeleton
        variant="rectangular"
        width="40%"
        height={50}
        sx={{ bgcolor: "#27b1bb", borderRadius: "10px" }}
      />
      {/* <Skeleton
        variant="text"
        width="60%"
        height={50}
        sx={{ mx: "auto", my: 2 }}
      /> */}
      <Typography
        component="h1"
        sx={{
          margin: "20px 0",
          fontSize: "28px",
          fontFamily: "Poppins",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        Loading Practice Test Questions Answers...
      </Typography>
      <StyledCard>
        <StickyHeader>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Box>
              <Skeleton variant="text" width={200} height={40} />
              <Skeleton variant="text" width={150} height={24} />
              <Skeleton variant="text" width={120} height={24} />
            </Box>
            <Skeleton
              variant="rectangular"
              width={100}
              height={80}
              sx={{ borderRadius: "16px" }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              gap: 2,
            }}
          >
            <Skeleton
              variant="rectangular"
              width={120}
              height={40}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              width={120}
              height={40}
              sx={{ borderRadius: "30px" }}
            />
            <Skeleton
              variant="rectangular"
              width={120}
              height={40}
              sx={{ borderRadius: "30px" }}
            />
          </Box>
        </StickyHeader>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InfoBox>
                <Skeleton
                  variant="circular"
                  width={30}
                  height={30}
                  sx={{ mr: 2 }}
                />
                <Box sx={{ width: "100%" }}>
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
              </InfoBox>
              <InfoBox>
                <Skeleton
                  variant="circular"
                  width={30}
                  height={30}
                  sx={{ mr: 2 }}
                />
                <Box sx={{ width: "100%" }}>
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
              </InfoBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoBox>
                <Skeleton
                  variant="circular"
                  width={30}
                  height={30}
                  sx={{ mr: 2 }}
                />
                <Box sx={{ width: "100%" }}>
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
              </InfoBox>
              <InfoBox>
                <Skeleton
                  variant="circular"
                  width={30}
                  height={30}
                  sx={{ mr: 2 }}
                />
                <Box sx={{ width: "100%" }}>
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="40%" />
                </Box>
              </InfoBox>
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                {[1, 2, 3].map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Skeleton variant="text" width="60%" />
                    <Skeleton
                      variant="rectangular"
                      height={10}
                      sx={{ my: 1 }}
                    />
                    <Skeleton variant="text" width="20%" sx={{ ml: "auto" }} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="40%" height={32} sx={{ mb: 2 }} />
              <Grid container spacing={1}>
                {[1, 2, 3, 4, 5].map((_, index) => (
                  <Grid item key={index}>
                    <Skeleton
                      variant="rectangular"
                      width={80}
                      height={32}
                      sx={{ borderRadius: "16px" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        {/* <Skeleton
          variant="rectangular"
          width={250}
          height={50}
          sx={{ borderRadius: 1, mr: 2 }}
        /> */}
        <Skeleton
          variant="rectangular"
          width={250}
          height={50}
          sx={{ borderRadius: 1 }}
        />
      </Box>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={1200}
        sx={{ mt: 4 }}
      />
    </Container>
  </Box>
);

const TitleAndContentSkeleton = () => (
  <>
    <Skeleton
      variant="rectangular"
      sx={{ height: 100, width: "90%", margin: "1rem auto" }}
    />
    <Skeleton
      variant="text"
      sx={{ fontSize: "2rem", width: "80%", margin: "0 auto" }}
    />
    {[...Array(15)].map((_, index) => (
      <Skeleton key={index} variant="text" width="100%" sx={{ mb: 1 }} />
    ))}
  </>
);

export { ExamDetailsSkeleton, TitleAndContentSkeleton };
